@charset "utf-8";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/600.css";
@import "@fontsource/montserrat/700.css";
@import "@fontsource/montserrat/800.css";
@import "@fontsource/montserrat/900.css";

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

@font-face{
  font-family:"Jost";
  src:url("./fonts/Jost400Book.otf")
}

@font-face{
  font-family:"Poppins";
  src:url("./fonts/Poppins-Regular.ttf")
}

@font-face{
  font-family:"Poppins-SemiBold";
  src:url("./fonts/Poppins-SemiBold.ttf")
}

@font-face{
  font-family:"AppleSDGothic";
  src:url("./fonts/AppleSDGothicNeoR.ttf")
}

@font-face{
  font-family:"AppleSDGothic-Bold";
  src:url("./fonts/AppleSDGothicNeoB.ttf")
}

*{
  user-select: none;
  font-family: 'Montserrat', 'Pretendard';
  font-style: normal;
  font-size: 16px;
}

input{
  border:1px solid #222;
}

input:focus { outline: none; }

body{
  margin:0px auto;
  min-width: 324px;
}

button{
  border: none;
  padding: 0px;
}

.root{
  position:relative;
  // search bar 외부 클릭시 닫기게 하는 div 위치잡기 and login alert 창 외부 클릭시 닫기게 하기 위해 필요
  // 클릭시 블록지정 안되게 방지
}

a{
  color: inherit;
  text-decoration: none;
}
a:visited, a:hover, a:active {
  color: inherit;
}

// react-slick dot area
.dots_custom{
  text-align:center;
}

.dots_custom ul{
  padding:0;
  margin-top:10px;
  margin-bottom:0px;
  li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
  }
}

.dots_custom li button {
  border: none;
  background: #555555;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background-color: #d8d8d8;
}
// react-slick dot area

.slick-prev::before,
.slick-next::before {
  opacity: 0;
  display: none;
}


.round{
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    border-radius: 100%;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    border-radius: 100%;
  }
}


.checkbox{ // signup, matchingPOP 쪽에서 쓰임
  text-align: left;

  label:focus-within {
    outline :1px solid red;
  }
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label
  {
      position: relative;
      margin:0 !important;
      font-weight: 500;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
  }
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border-radius:4px;
      border: solid 1px #cecece;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 2px;
      background: #222;
      position: absolute;
      top: 4px;
      left: 4px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}
.viewMore{
  cursor:pointer;
  width: 172px;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  border: solid 1px #000;
  margin: 0 auto;
  transition: 0.25s;
  text-align: center;
}
.viewMore:hover{
  background-color:#f5f5f5;
}

.user_type{
  width: 52px;
  height: 22px;
  line-height:22px;
  padding: 4px 10px;
  // box-sizing: border-box;
  border-radius: 2px;
  background-color: #eff4ff;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: bold;
  color: #0053ff;
  text-align:center;
}

.blueButton{
  border-radius: 4px;
  background-color: #0053ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.04px;
  color: #fff;
}

.whiteButton{
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.04px;
  color: #666;
  box-sizing: border-box;
}

.grayButton{
  border-radius: 4px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
  color: #292929;
}

.blackButton{
  border-radius: 4px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.37px;
  color: #fff !important;
}

.noContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  height: 520px;
  border-radius: 4px;
  background-color: #f8f8f8;
  > span{
    font-size: 32px;
  }
  > p{
    margin: 22px auto 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.02px;
    color: #606063;
    text-align: center;
  }
  > div{
    width: 180px;
    height: 42px;
    margin: 52px auto 0;
  }
}

@media (max-width: 720px) {
  *{
    user-select: none;
    font-style: normal;
    font-size: 14px;
  }
  .noContent{
    background-color: white;
    height: initial;
    margin-bottom:133px;
  }
}
